import React, {FormEvent} from 'react';

import * as classNames from "@css/component/UpdateMeasurementLabel.module.scss";

/**
 * @type UpdateMeasurementLabelProps
 */
type UpdateMeasurementLabelProps = {
    labelText: string;
    labelType: string;
    defaultValue: string | null;
    onChange: (event: FormEvent<HTMLSelectElement>) => void;
};

const UpdateMeasurementLabel = (props: UpdateMeasurementLabelProps) => {
    const {
        labelText,
        labelType,
        defaultValue,
        onChange,
        ...restProps
    } = props;

    const energyLabels = ["No label","A++++", "A+++", "A++", "A+", "A", "B", "C", "D", "E", "F", "G"];

    return (
        <div { ...restProps } className={ classNames.updateMeasurementLabel }>
            <label className={ classNames.label }>
                { labelText }
            </label>
            <select
                name={ labelType }
                className={ classNames.select }
                onChange={ onChange }
                defaultValue={ defaultValue ? defaultValue : "No label" }
            >
                { energyLabels.map((item, index) => (
                    <option key={ index } value={ item }>
                        { "No label" === item ? "Geen label" : item }
                    </option>
                ))}
            </select>
        </div>
    );
}

export default UpdateMeasurementLabel;