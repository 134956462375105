import React from "react";

import { DetailedHTMLProps } from "react";
import { InputHTMLAttributes } from "react";

import clsx from "clsx";

import * as classNames from "@css/component/Switch.module.scss";

/**
 * @type SwitchProps
 */
export type SwitchProps = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

/**
 * @const Switch
 */
const Switch = (props: SwitchProps) => {
    const {
        className,
        ...restProps
    } = props;

    return (
        <label>
            <input { ...restProps } className={ classNames.input } type="checkbox" />
            <div className={ clsx(classNames._switch, className) }>
                <div className={ classNames.handle } />
            </div>
        </label>
    );
};

export default Switch;
