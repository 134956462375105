import React, { useEffect, useState } from 'react';
import DatePicker, { registerLocale } from "react-datepicker";

import * as classNames from "@css/component/UpdateMeasurementDate.module.scss";
import "react-datepicker/dist/react-datepicker.css";

import {nl} from 'date-fns/locale/nl';
registerLocale('nl', nl);

/**
 * @type UpdateMeasurementDateProps
 */
type UpdateMeasurementDateProps = {
    labelText: string;
    defaultValue: Date | null;
    minDate: Date | null,
    maxDate: Date | null,
    onChange: (date: Date | null) => void;
    disabled?: boolean;
};

const UpdateMeasurementDate = (props: UpdateMeasurementDateProps) => {
    const {
        labelText,
        defaultValue,
        minDate,
        maxDate,
        onChange,
        disabled,
        ...restProps
    } = props;

    const [measurementAppointmentDate, setMeasurementAppointmentDate] = useState<Date | null>(null);

    useEffect(() => {
        setMeasurementAppointmentDate(defaultValue);
    }, []);

    return (
        <div { ...restProps } className={ classNames.updateMeasurementDate }>
            <label className={ classNames.label }>
                { labelText }
            </label>
            <DatePicker
                locale="nl"
                className={ classNames.datePicker }
                selected={ measurementAppointmentDate }
                dateFormat="dd-MM-yyyy"
                onChange={ (date) => {
                    onChange(date);
                    setMeasurementAppointmentDate(date);
                } }
                minDate={ minDate }
                maxDate={ maxDate }
                placeholderText="Selecteer een datum"
                disabled={ disabled }
            />
        </div>
    );
}

export default UpdateMeasurementDate;