import React from "react";

import { SVGProps } from "react";

/**
 * @type FilterIconProps
 */
export type FilterIconProps = Omit<SVGProps<SVGSVGElement>, "children">;

/**
 * @const FilterIcon
 */
const FilterIcon = (props: FilterIconProps) => {
    return (
        <svg {...props} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path
                d="M27.6 64C12.4 64 0 76.4 0 91.6c0 6.7 2.4 13.2 6.8 18.2L112 230V360c0 10 4.7 19.5 12.7 25.5l76.2 57.5c4.2 3.2 9.4 4.9 14.7 4.9c13.5 0 24.4-10.9 24.4-24.4V230L345.2 109.8c4.4-5 6.8-11.5 6.8-18.2C352 76.4 339.6 64 324.4 64H27.6zM140 213.5L37.3 96H314.7L212 213.5c-2.6 2.9-4 6.7-4 10.5V408.3L144 360V224c0-3.9-1.4-7.6-4-10.5zM336 400c-8.8 0-16 7.2-16 16s7.2 16 16 16H496c8.8 0 16-7.2 16-16s-7.2-16-16-16H336zM320 256c0 8.8 7.2 16 16 16H496c8.8 0 16-7.2 16-16s-7.2-16-16-16H336c-8.8 0-16 7.2-16 16zM400 80c-8.8 0-16 7.2-16 16s7.2 16 16 16h96c8.8 0 16-7.2 16-16s-7.2-16-16-16H400z"
                fill="currentColor"/>
        </svg>
    );
};

export default FilterIcon;
