import ReactPaginate from "react-paginate";

import React, {ChangeEvent, DetailedHTMLProps, FormEvent, Fragment, HTMLAttributes, useEffect, useState} from "react";

import clsx from "clsx";

import moment from "moment";

import {graphql, useStaticQuery} from "gatsby";
import {useDispatch, useSelector} from "react-redux";

import Badge from "@js/component/Badge";
import Button from "@js/component/Button";
import ChevronDownIcon from "@js/component/icon/ChevronDownIcon";
import ChevronUpIcon from "@js/component/icon/ChevronUpIcon";
import Heading from "@js/component/Heading";
import Input from "@js/component/Input";
import Select from "@js/component/Select";
import Switch from "@js/component/Switch";
import Textarea from "@js/component/Textarea";

import StoreState from "@js/store/StoreState";

import useDebounce from "@js/hook/useDebounce";

import {setOpen as setOpenAction} from "@js/actionCreators/statusActionCreators";

import * as classNames from "@css/component/MyRequestsView.module.scss";
import TruncateText from "@js/component/TruncateText";
import UpdateMeasurementLabel from "@js/component/UpdateMeasurementLabel";
import LabelFileUpdater from "@js/component/LabelFileUpdater";
import UpdateMeasurementDate from "@js/component/UpdateMeasurementDate";
import InputRadio from "@js/component/InputRadio";
import InputCheckbox from "@js/component/InputCheckbox";
import FilterIcon from "@js/component/icon/FilterIcon";

/**
 * Request
 */
type Request = {
    activatedAt: string | null;
    advisor: {
        companyLogo: string;
        companyLogoName: string;
        companyName: string;
        companyPromo: string;
        deliveryTime: number;
        email: string;
        firstName: string;
        id: number;
        lastName: string;
        priceTiers: number[];
        reviewAverage: number;
        status: string;
        strongPoints: {
            id: number;
            message: string;
            sortOrder: number;
        }[];
        url: string;
        workAreas: number[];
    };
    area: string;
    areaBag: string;
    areaManual: string;
    areaName: string;
    buildingName: string;
    buildingOwnerAffix: string;
    buildingOwnerInitials: string;
    buildingOwnerName: string;
    buildingOwnerPhone: string;
    buildingOwnerTitle: string;
    buildingOwnerZipcode: string;
    buildingOwnerCompanyName: string;
    billingStreet: string;
    billingHouseNumber: number;
    billingHouseNumberSuffix: number;
    billingZipcode: string;
    billingCity: string;
    requestContact: {
        firstName: string;
        affix: string;
        name: string;
        email: string;
        phone: string;
    },
    buildingType: string;
    buildingTypeCategory:string;
    city: string;
    completed: string;
    conflict: {
        advisorNote?: string;
        advisorNoteAt?: string;
        buildingOwnerAgreed: boolean;
        buildingOwnerNote?: string;
        buildingOwnerNoteAt: string;
        createdAt: string;
        // reason: {
        //     code: string;
        //     name: string;
        // };
        updatedAt: string;
    };
    createdAt: string;
    deliveryTime: number;
    energyLabelRating: string | null;
    energyLabelRatingFirstMeasurement: string | null;
    energyLabelRatingFinalMeasurement: string | null;
    firstMeasurementAppointmentPlannedAt: Date | null;
    finalMeasurementAppointmentPlannedAt: Date | null;
    capacityStartDate: Date | null;
    capacityEndDate: Date | null;
    planFirstMeasurementAppointmentDeadline: Date | null;
    finishFirstMeasurementAppointmentDeadline: Date | null;
    email: string;
    extraInfoMessage: string;
    extraInfoVisit?: string;
    houseNumber: string;
    houseNumberSuffix: string;
    id: number;
    methodology: string;
    methodologyName: string;
    orderNumber: number;
    orderFilename?: string;
    partnership: {
        id: number,
        name: string,
        enabled: boolean
    },
    files: {
        vabiFile: {
            name: string;
            url: string;
        },
        energyLabelFile: {
            name: string;
            url: string;
        },
        vabiFilePhaseOne: {
            name: string;
            url: string;
        },
        vabiExportFilePhaseOne: {
            name: string;
            url: string;
        }
        energyLabelFirstMeasurementFile: {
            name: string;
            url: string;
        },
        customizedAdviceFile: {
            name: string;
            url: string;
        },
        vabiFilePhaseTwo: {
            name: string;
            url: string;
        },
        vabiExportFilePhaseTwo: {
            name: string;
            url: string;
        },
        energyLabelFinalMeasurementFile: {
            name: string;
            url: string;
        }
    },
    price: string;
    requiresQuote: boolean;
    priceTier: number;
    review: string;
    reviewMessage: string;
    reviewResponse: string;
    seenByAdvisorDate: string;
    street: string;
    workingdays: number;
    workOrder: {
        status: {
            code: string;
            name: string;
        }
    };
    adviceMeeting: boolean|null;
    adviceMeetingPhysical: boolean|null;
};

/**
 * @type RequestRejectReasonData
 */
type RequestRejectReasonData = {
    allRequestRejectReason: {
        nodes: {
            code: string;
            name: string;
        }[];
    };
};

/**
 * @type FilterData
 */
type FilterData = {
    filter: {
        options: {
            buildingType: {
                label: string;
                value: string;
                category: string;
                areas: {
                    label: string;
                    value: string;
                    quote: boolean;
                }[];
            }[];
        };
    };
};

/**
 * @type Data
 */
type Data = RequestRejectReasonData & FilterData;

/**
 * @type MyRequestsViewProps
 */
export type MyRequestsViewProps = Omit<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, "children">;

/**
 * @const MyRequestsView
 */
const MyRequestsView = (props: MyRequestsViewProps) => {
    const {
        className,
        ...restProps
    } = props;

    const dispatch = useDispatch();
    const debounce = useDebounce();

    const data: Data = useStaticQuery(graphql`
        query {
            allRequestRejectReason {
                nodes {
                    code
                    name
                }
            }
            filter {
                options {
                    buildingType {
                        label
                        value
                        category
                        areas {
                            label
                            value
                            quote
                        }
                    }
                }
            }
        }
    `);

    const [open, setOpen] = useState(0);

    const [tab, setTab] = useState("new");

    const [requests, setRequests ] = useState<Request[]>([]);
    const [orderBy, setOrderBy] = useState("");
    const [orderDirection, setOrderDirection] = useState("");
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(0);
    const [isFetchingRequests, setIsFetchingRequests] = useState(true);
    const [search, setSearch] = useState("");
    const [requestType, setRequestType] = useState("");
    const [productChoice, setProductChoice] = useState("");
    const [requestPriority, setRequestPriority] = useState(false);
    const [showFilters, setShowFilters] = useState(false);
    const [actualTab, setSActualTab] = useState("new");
    const [firstMeasurementAppointmentDate, setFirstMeasurementAppointmentDate] = useState<Date>();
    const [finalMeasurementAppointmentDate, setFinalMeasurementAppointmentDate] = useState<Date>();
    const [firstMeasurementLabel, setFirstMeasurementLabel] = useState("");
    const [finalMeasurementLabel, setFinalMeasurementLabel] = useState("");
    const [advisoralLectureGiven, setAdvisoralLectureGiven] = useState(null);
    const [advisoralLectureGivenPhysical, setAdvisoralLectureGivenPhysical] = useState(null);

    // const [conflictReason, setConflictReason] = useState("");
    const [conflictExplanation, setConflictExplanation] = useState("");
    const [labelErrors, setLabelErrors] = useState<string>("");
    const [success, setSuccess] = useState<string>("");

    const openRequests = useSelector((storeState: StoreState) => storeState.status.open);
    const advisorHasPartnerships = useSelector((storeState: StoreState) => storeState.status.hasPartnerships);
    const token = useSelector((storeState: StoreState) => storeState.security.token);

    const getRequests = async () => {
        const response = await fetch(`${ process.env.GATSBY_API_ADVISOR_URL }/request/search?token=${ token }`, {
            body: JSON.stringify({
                filters: {
                    search: search,
                    status: tab,
                    type: requestType,
                    priority: requestPriority,
                    productChoice: productChoice
                },
                orderBy: orderBy,
                orderDirection: orderDirection,
                page: page
            }),
            method: "POST"
        });

        const data = await response.json();

        setRequests(data.result);
        setPages(data.pages);
        setOpen(0);
        setIsFetchingRequests(false);

        setSActualTab(tab);
    };

    const dateTimeFormat = new Intl.DateTimeFormat("nl-NL");


    const onConflictFormSubmit = (request: Request) => async (event: FormEvent) => {
        event.preventDefault();

        const response = await fetch(`${ process.env.GATSBY_API_ADVISOR_URL }/request/conflict/${ request.id }?token=${ token }`, {
            body: JSON.stringify({
                //"rejectReason": conflictReason,
                "advisorNote": conflictExplanation
            }),
            method: "PUT"
        });

        getRequests();
    };

    const onToggleButtonClick = (id: number) => () => {
        if (open == id) {
            setOpen(0);
        }
        else {
            setOpen(id);
        }

        setLabelErrors(null);
        setSuccess(null);
        setFirstMeasurementAppointmentDate(null);
        setFinalMeasurementAppointmentDate(null);
        setFirstMeasurementLabel("");
        setFinalMeasurementLabel("");
        setAdvisoralLectureGiven(null);
        setAdvisoralLectureGivenPhysical(null);
    };

    const onSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const split = event.currentTarget.value.split("-");

        setOrderBy(split[0]);
        setOrderDirection(split[1]);
    };

    const onRequestTypeChange = (event: ChangeEvent<HTMLSelectElement>) => {
        setRequestType(event.currentTarget.value);
    };

    const onProductChoiceChange = (event: ChangeEvent<HTMLSelectElement>) => {
        setProductChoice(event.currentTarget.value);
    };

    const onSwitchChange = (request: Request) => () => {
        const copyOfRequests = [ ...requests ];

        const index = copyOfRequests.findIndex((r) => {
            return r == request;
        });

        let activated = true;

        if (copyOfRequests[index].activatedAt) {
            copyOfRequests[index].activatedAt = null;

            activated = false;

            dispatch(setOpenAction(openRequests + 1));
        }
        else {
            copyOfRequests[index].activatedAt = "1";

            activated = true;

            dispatch(setOpenAction(openRequests - 1));
        }

        setRequests(copyOfRequests);

        debounce(async () => {
            const response = await fetch(`${ process.env.GATSBY_API_ADVISOR_URL }/request/contacted/${ request.id }?token=${ token }`, {
                body: JSON.stringify({
                    activated: activated
                }),
                method: "PUT"
            });

            getRequests();
        });
    };

    const onDeliverButtonClick = (request: Request) => async () => {
        const response = await fetch(`${ process.env.GATSBY_API_ADVISOR_URL }/request/completed/${ request.id }?token=${ token }`, {
            body: JSON.stringify({
                completed: true
            }),
            method: "PUT"
        });

        getRequests();
    };

    const onUpdateMeasurementDatesButtonClick = (request: Request) => async (event: FormEvent) => {
        event.preventDefault();

        const measurementData = {};

        if(firstMeasurementAppointmentDate) {
            measurementData["firstMeasurementAppointmentPlannedAt"] = firstMeasurementAppointmentDate;
        }

        if (advisoralLectureGiven !== null) {
            measurementData["adviceMeeting"] = advisoralLectureGiven;
            measurementData["adviceMeetingPhysical"] = advisoralLectureGivenPhysical;
        }

        if(firstMeasurementLabel) {
            measurementData["energyLabelRatingFirstMeasurement"] = firstMeasurementLabel;
        }

        if(finalMeasurementAppointmentDate) {
            measurementData["finalMeasurementAppointmentPlannedAt"] = finalMeasurementAppointmentDate;
        }

        if(finalMeasurementLabel) {
            measurementData["energyLabelRatingFinalMeasurement"] = finalMeasurementLabel;
        }

        if(request.workOrder.status.code === "planned_1" &&
            (!firstMeasurementAppointmentDate && !request.firstMeasurementAppointmentPlannedAt || !firstMeasurementLabel && !request.energyLabelRatingFirstMeasurement) &&
            (!request.files.vabiFile || !request.files.energyLabelFile || !request.files.vabiFilePhaseOne || !request.files.vabiExportFilePhaseOne)) {
            setLabelErrors("Upload alle benodigde bestanden en vul de opnamedatum, energielabel 0-meting in");
            return false;
        }

        if(request.workOrder.status.code === "planned_2" &&
            (!finalMeasurementAppointmentDate && !request.finalMeasurementAppointmentPlannedAt || !finalMeasurementLabel && !request.energyLabelRatingFinalMeasurement) &&
            (!request.files.vabiFile || !request.files.energyLabelFile || !request.files.vabiFilePhaseOne || !request.files.vabiExportFilePhaseOne || !request.files.vabiFilePhaseTwo || !request.files.vabiExportFilePhaseTwo || !request.files.energyLabelFinalMeasurementFile )) {
            setLabelErrors("Upload alle benodigde bestanden en vul de opnamedatum, energielabel eind-meting in");
            return false;
        }

        if(Object.keys(measurementData).length > 0) {
            fetch(`${process.env.GATSBY_API_ADVISOR_URL}/request/${request.id}?token=${token}`, {
                body: JSON.stringify(measurementData),
                method: "PATCH"
            })
                .then((response) => response.json())
                .then((data) => {
                    if ("succeeded" === data.status) {
                        setLabelErrors(null);
                        setSuccess(`De gegevens zijn bijgewerkt`);

                        debounce(() => {
                            getRequests();
                        });

                    } else {
                        if (data.errors) {
                            Object.entries(data.errors).map((reason: string) => {
                                setLabelErrors(reason[1]);
                                setSuccess(null);
                            });
                        }
                    }

                }).catch((error) => {
                console.log(error);
            });
        } else {
            // Refresh the page
            await getRequests();
        }
    };

    const onPlannedButtonClick = (request: Request) => async () => {
        const response = await fetch(`${ process.env.GATSBY_API_ADVISOR_URL }/request/planned/${ request.id }?token=${ token }`, {
            body: JSON.stringify({
                planned: true
            }),
            method: "PUT"
        });

        getRequests();
    };

    useEffect(() => {
        setIsFetchingRequests(true);
        setRequests([]);

        debounce(() => {
            getRequests();
        });
    }, [tab, page, search, orderBy, orderDirection, requestType, requestPriority, productChoice]);

    useEffect(() => {
        setOpen(0);
    }, [tab]);

    useEffect(() => {
        //setConflictReason("");
        setConflictExplanation("");
    }, [open]);

    return (
        <div { ...restProps } className={ clsx(classNames.myRequestsView, className) }>
            <div className={classNames.container}>
                <Heading className={classNames.heading} element="h1" variant="medium">Mijn aanvragen</Heading>
                <p className={classNames.paragraph}>
                    Op deze pagina vind je de opdrachten die je via Woninglabel.nl hebt ontvangen.
                    Het getal bij ‘Mijn aanvragen’ in het hoofdscherm en linksboven in het snelmenu geeft het aantal
                    nieuw ontvangen opdrachten weer.
                    Deze aanvragen vind je terug in het overzicht onder de tab Aangevraagd.
                    We herinneren je middels mailnotificaties er ook aan dat hier nog aanvragen openstaan.
                    Heb je enige vorm van contact een klant gelegd? Dan geef je dat gemakkelijk aan middels het schuifje
                    in de opdracht.
                    Deze opdracht vind je daarna terug onder de tab Contact en kan je middels de knop verplaatsen naar
                    Ingepland.
                    Heb je de klant voorzien van jouw product? Klik dan op de afmeldknop zodat wij jouw klant kunnen
                    vragen om een review voor je te schrijven.
                    Je vindt de voltooide opdracht terug onder het tabje ‘Afgerond’. Kan de opdracht onverhoopt niet
                    uitgevoerd worden?
                    Laat het ons weten door de reden hiervoor in de opdracht aan te geven en een wijzigingsverzoek in te
                    dienen.
                    Doe je dit binnen 7 dagen nadat je de opdracht hebt ontvangen?
                    Dan controleren we jouw verzoek bij de klant en beoordelen we of we de aanvraag wel of niet bij je
                    in rekening brengen.
                    Doe je dit vanaf dag 8 na aanvraag dan brengen we opdrachtvergoeding altijd in rekening.
                </p>
                <Select
                    className={classNames.filter}
                    onChange={(event) => setTab(event.currentTarget.value)}
                    value={tab}
                >
                    <option value="new">Aangevraagd</option>
                    <option value="processing">Contact</option>
                    <option value="planned">Ingepland</option>
                    <option value="conflicted">Conflict</option>
                    <option value="canceled">Geannuleerd</option>
                    <option value="expired">Verlopen</option>
                    <option value="completed">Afgerond</option>
                </Select>
                <Button
                    className={ classNames.filterButtonMobile }
                    variant="green"
                    onClick={ () => setShowFilters(!showFilters) }
                >
                    <FilterIcon className={ classNames.icon } />
                    <span>Filter</span>
                </Button>
                <div className={classNames.tabs}>
                    <button
                        className={clsx(classNames.tab, {[classNames.active]: tab == "new"})}
                        onClick={() => setTab("new")}
                    >
                        Aangevraagd
                    </button>
                    <button
                        className={clsx(classNames.tab, {[classNames.active]: tab == "processing"})}
                        onClick={() => setTab("processing")}
                    >
                        Contact
                    </button>
                    <button
                        className={clsx(classNames.tab, {[classNames.active]: tab == "planned"})}
                        onClick={() => setTab("planned")}
                    >
                        Ingepland
                    </button>
                    <button
                        className={clsx(classNames.tab, {[classNames.active]: tab == "conflicted"})}
                        onClick={() => setTab("conflicted")}
                    >
                        Conflict
                    </button>
                    <button
                        className={clsx(classNames.tab, {[classNames.active]: tab == "expired"})}
                        onClick={() => setTab("expired")}
                    >
                        Verlopen
                    </button>
                    <button
                        className={clsx(classNames.tab, {[classNames.active]: tab == "canceled"})}
                        onClick={() => setTab("canceled")}
                    >
                        Geannuleerd
                    </button>
                    <button
                        className={clsx(classNames.tab, {[classNames.active]: tab == "completed"})}
                        onClick={() => setTab("completed")}
                    >
                        Afgerond
                    </button>
                    <Button
                        className={ classNames.filterButton }
                        variant="green"
                        onClick={ () => setShowFilters(!showFilters) }
                    >
                        <FilterIcon className={ classNames.icon } />
                        <span>Filter</span>
                    </Button>
                </div>

                { showFilters &&
                    <div className={classNames.searchWrapper}>
                        <div className={classNames.searchRequest}>
                            <Input
                                className={classNames.input}
                                name="search"
                                onChange={(event) => {
                                    setPage(1);
                                    setSearch(event.currentTarget.value);
                                }}
                                placeholder="Zoek op klantnaam of adres"
                                value={search}
                            />

                            <Select className={classNames.sort} name="productChoice" onChange={ onProductChoiceChange }>
                                <option disabled value=""> -- Aanvraag soort --</option>
                                <option value="all">Toon alle soorten</option>
                                <option value="energyLabel">Alleen energielabels</option>
                                <option value="customAdvice">Alleen maatwerk-advies</option>
                            </Select>

                            { advisorHasPartnerships &&
                                <Select className={classNames.sort} name="requestType" onChange={ onRequestTypeChange }>
                                    <option disabled value=""> -- Aanvraag type --</option>
                                    <option value="all">Toon alle aanvragen</option>
                                    <option value="default">Alleen standaard aanvragen</option>
                                    <option value="partnership">Alleen partnertraject aanvragen</option>
                                </Select>
                            }

                            <InputCheckbox
                                labelClassName={ classNames.inputCheckboxLabel }
                                name="priority"
                                onChange={ () => setRequestPriority(!requestPriority) }
                            >
                                Toon aanvragen met prioriteit
                            </InputCheckbox>
                        </div>
                    </div>
                }

                <div className={classNames.sortOptions}>
                    <Select name="sort" onChange={onSelectChange}>
                        <option disabled value=""> -- Sorteren --</option>
                        <option value="createdAt-asc">Aanvraag - oplopend</option>
                        <option value="createdAt-desc">Aanvraag - aflopend</option>
                        <option value="buildingOwnerName-asc">Klant - oplopend</option>
                        <option value="buildingOwnerName-desc">Klant - aflopend</option>
                        <option value="city-asc">Woonplaats - oplopend</option>
                        <option value="city-desc">Woonplaats - aflopend</option>
                        <option value="buildingOwnerZipcode-asc">Postcode - oplopend</option>
                        <option value="buildingOwnerZipcode-desc">Postcode - aflopend</option>
                        <option value="deliveryTime-asc">Levertijd - oplopend</option>
                        <option value="deliveryTime-desc">Levertijd - aflopend</option>
                    </Select>
                </div>

                <table className={classNames.table}>
                    <thead className={classNames.head}>
                    <tr className={classNames.row}>
                        <th className={classNames.heading}>&nbsp;</th>
                        <th className={classNames.heading}>
                            Aanvraag
                        </th>
                        <th className={classNames.heading}>
                            Klant
                        </th>
                        <th className={classNames.heading}>
                            Adres
                        </th>
                        {(actualTab == "new") && (
                            <th className={classNames.heading}>
                                Openstaand
                            </th>
                        )}
                        {(actualTab == "processing" || actualTab == "planned" || actualTab == "completed") && (
                            <th className={classNames.heading}>
                                Levertijd
                            </th>
                        )}
                        {!(actualTab == "new" || actualTab == "processing" || actualTab == "planned" || actualTab == "completed") && (
                            <th className={classNames.heading}>&nbsp;</th>
                        )}
                        <th className={classNames.heading}>&nbsp;</th>
                    </tr>
                    </thead>
                    <tbody className={classNames.body}>
                    {isFetchingRequests &&
                        <tr className={classNames.row}>
                            <td className={clsx(classNames.data, classNames.dataFetching)} colSpan={7}>
                                <div className={classNames.fetching}>De aanvragen worden opgehaald...</div>
                            </td>
                        </tr>
                    }
                    {!(isFetchingRequests) &&
                        <>
                            {requests.length == 0 &&
                                <tr className={classNames.row}>
                                    <td className={clsx(classNames.data, classNames.dataNoResults)} colSpan={7}>
                                        <div className={classNames.noResults}>Er zijn geen aanvragen gevonden.</div>
                                    </td>
                                </tr>
                            }
                            {requests.length > 0 && requests.map((request) => {
                                const today = new Date();
                                const createdAt = new Date(request.createdAt);

                                const difference = (today.getTime() - createdAt.getTime());
                                const differenceInDays = difference / (1000 * 3600 * 24);

                                const days = Math.round(request.deliveryTime - differenceInDays);

                                const streetAddress = `${request.street} ${request.houseNumber} ${request.houseNumberSuffix ?? ''}`;
                                const billingAddress = `${request.billingStreet} ${request.billingHouseNumber} ${request.billingHouseNumberSuffix ?? ''}`;

                                const firstMeasurementDate = request.firstMeasurementAppointmentPlannedAt;
                                const finalMeasurementDate = request.finalMeasurementAppointmentPlannedAt;
                                const adviceMeeting = request.adviceMeeting !== null ? request.adviceMeeting : null;
                                const adviceMeetingPhysical = request.adviceMeetingPhysical !== null ? request.adviceMeetingPhysical : null;

                                const partnershipContactDate = request.planFirstMeasurementAppointmentDeadline ? dateTimeFormat.format(new Date(request.planFirstMeasurementAppointmentDeadline)) : 'niet bekend';
                                const partnershipContactFinalDate = request.finishFirstMeasurementAppointmentDeadline ? dateTimeFormat.format(new Date(request.finishFirstMeasurementAppointmentDeadline)) : '---';

                                const partnershipFinalMeasurementContactDate = request.planFinalMeasurementAppointmentDeadline ? dateTimeFormat.format(new Date(request.planFinalMeasurementAppointmentDeadline)) : 'niet bekend';
                                const partnershipFinalMeasurementContactFinalDate = request.finishFinalMeasurementAppointmentDeadline ? dateTimeFormat.format(new Date(request.finishFinalMeasurementAppointmentDeadline)) : '---';

                                return (
                                    <Fragment key={request.id}>
                                        <tr className={clsx(classNames.row, {
                                            [classNames.rowOpenTop]: open == request.id
                                        })}>
                                            <td className={clsx(
                                                classNames.data,
                                                classNames.dataCustomerContact
                                            )}>
                                                {actualTab == "new" && (
                                                    <div
                                                        className={clsx(classNames.contact, {
                                                            [classNames.red]: request.activatedAt == null,
                                                            [classNames.green]: !(request.activatedAt == null)
                                                        })
                                                        }
                                                        title={`Klantcontact: ${request.activatedAt == null ? 'Nee' : 'Ja'}`}
                                                    />
                                                )}
                                            </td>
                                            <td className={clsx(classNames.data, classNames.column)}
                                                data-heading="Aanvraag">
                                                {dateTimeFormat.format(createdAt)}
                                                {request.partnership && request.orderNumber &&
                                                    <small className={classNames.small}>
                                                        ID: {request.orderNumber}
                                                    </small>
                                                }
                                            </td>
                                            <td className={classNames.data} data-heading="Klant">
                                                <TruncateText
                                                    textInput={request.buildingOwnerInitials + request.buildingOwnerAffix + request.buildingOwnerName}
                                                    textOutput={`${request.buildingOwnerInitials} ${request.buildingOwnerAffix ? request.buildingOwnerAffix : ''} ${request.buildingOwnerName}`}
                                                    count={22}
                                                />
                                            </td>
                                            <td className={classNames.data} data-heading="Adres">
                                                <TruncateText
                                                    textInput={request.street + request.houseNumber + request.houseNumberSuffix}
                                                    textOutput={`${request.street} ${request.houseNumber} ${request.houseNumberSuffix ? request.houseNumberSuffix : ''}`}
                                                    count={25}
                                                />
                                                <br/>
                                                <TruncateText
                                                    textInput={request.buildingOwnerZipcode + request.city}
                                                    textOutput={`${request.buildingOwnerZipcode} ${request.city}`}
                                                    count={30}
                                                />
                                            </td>
                                            {(actualTab == "new") && (
                                                <td className={classNames.data} data-heading="Openstaand">
                                                    {!request.partnership &&
                                                        <Badge className={clsx(classNames.badge, {
                                                            [classNames.badgeOrange]: !(request.completed) && request.workingdays >= 1 && request.workingdays < 2,
                                                            [classNames.badgeRed]: !(request.completed) && request.workingdays >= 2 && request.workingdays < 3,
                                                            [classNames.badgeDarkRed]: !(request.completed) && request.workingdays > 3,
                                                            [classNames.badgeGrey]: request.completed
                                                        })}>
                                                            {request.workingdays} werkdag(en)
                                                        </Badge>
                                                    }

                                                    {request.partnership && request.workOrder && request.workOrder.status.code === 'requested' &&
                                                        <Badge className={classNames.badgePartnership}>
                                                            Contact opnemen voor: {partnershipContactDate}
                                                        </Badge>
                                                    }

                                                    {request.partnership && request.workOrder && request.workOrder.status.code === 'planning_2' &&
                                                        <Badge className={classNames.badgePartnership}>
                                                            Contact opnemen voor: {partnershipFinalMeasurementContactDate}
                                                        </Badge>
                                                    }
                                                </td>
                                            )}
                                            {(actualTab == "processing" || actualTab == "planned" || actualTab == "completed") && (
                                                <td className={classNames.data} data-heading="Levertijd">
                                                    {!request.partnership &&
                                                        <Badge className={clsx({
                                                            [classNames.badgeRed]: !(request.completed) && days < 1,
                                                            [classNames.badgeOrange]: !(request.completed) && days == 1,
                                                            [classNames.badgeGrey]: request.completed
                                                        })}>
                                                            {days} werkdag(en)
                                                        </Badge>
                                                    }

                                                    {request.partnership && request.workOrder && (request.workOrder.status.code === 'planned_1' || request.workOrder.status.code === 'requested') &&
                                                        <Badge className={classNames.badgePartnership}>
                                                            Producten opleveren voor {partnershipContactFinalDate}
                                                        </Badge>
                                                    }

                                                    {request.partnership && request.workOrder && (request.workOrder.status.code === 'planned_2' || request.workOrder.status.code === 'planning_2') &&
                                                        <Badge className={classNames.badgePartnership}>
                                                            Producten opleveren voor {partnershipFinalMeasurementContactFinalDate}
                                                        </Badge>
                                                    }

                                                    {request.partnership && actualTab == "completed" &&
                                                        <Badge className={classNames.badgePartnership}>
                                                            Afgerond
                                                        </Badge>
                                                    }
                                                </td>
                                            )}
                                            {!(actualTab == "new" || actualTab == "processing" || actualTab == "planned" || actualTab == "completed") && (
                                                <td className={classNames.data}>&nbsp;</td>
                                            )}
                                            <td className={classNames.data}>
                                                <button
                                                    className={classNames.button}
                                                    onClick={onToggleButtonClick(request.id)}
                                                >
                                                    {!(open == request.id) &&
                                                        <ChevronDownIcon className={classNames.icon}/>
                                                    }
                                                    {(open == request.id) &&
                                                        <ChevronUpIcon className={classNames.icon}/>
                                                    }
                                                </button>
                                            </td>
                                        </tr>
                                        {open == request.id &&
                                            <tr className={clsx(classNames.row, classNames.rowOpenBottom)}>
                                                <td
                                                    className={clsx(classNames.data, classNames.dataColSpan)}
                                                    colSpan={7}
                                                >
                                                    <div className={classNames.blocks}>
                                                        <div className={classNames.blockOne}>
                                                            <div className={classNames.informationOne}>
                                                                <label className={classNames.heading}>Adresgegevens
                                                                    gebouw</label>
                                                                <div>
                                                                    <TruncateText
                                                                        textInput={streetAddress}
                                                                        textOutput={streetAddress}
                                                                        count={25}
                                                                    />
                                                                    <br/>
                                                                    <TruncateText
                                                                        textInput={request.buildingOwnerZipcode + request.city}
                                                                        textOutput={`${request.buildingOwnerZipcode} ${request.city}`}
                                                                        count={25}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className={classNames.informationTwo}>
                                                                <label className={classNames.heading}>Gegevens
                                                                    aanvrager</label>
                                                                {request.buildingOwnerCompanyName &&
                                                                    <>{request.buildingOwnerCompanyName}<br/></>
                                                                }
                                                                <TruncateText
                                                                    textInput={request.buildingOwnerInitials + request.buildingOwnerAffix + request.buildingOwnerName}
                                                                    textOutput={`${request.buildingOwnerInitials} ${request.buildingOwnerAffix ? request.buildingOwnerAffix : ''} ${request.buildingOwnerName}`}
                                                                    count={25}
                                                                />
                                                                <a href={`tel:${request.buildingOwnerPhone}`}>
                                                                    {request.buildingOwnerPhone}
                                                                </a>
                                                                <a
                                                                    href={`mailto:${request.email}`}
                                                                >
                                                                    <TruncateText
                                                                        textInput={request.email}
                                                                        textOutput={request.email}
                                                                        count={25}
                                                                    />
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className={classNames.blockTwo}>
                                                            <table className={classNames.table}>
                                                                <tbody className={classNames.body}>
                                                                <tr className={classNames.row}>
                                                                    <th className={classNames.heading}>
                                                                        Type gebouw
                                                                    </th>
                                                                    <td className={classNames.data}>
                                                                        {request.buildingTypeCategory == 'private' && <>Woning<br/></>}
                                                                        {request.buildingName}
                                                                    </td>
                                                                </tr>
                                                                {request.areaBag &&
                                                                    <tr className={classNames.row}>
                                                                        <th className={classNames.heading}>
                                                                            BAG-oppervlakte
                                                                        </th>
                                                                        <td className={classNames.data}>
                                                                            {request.areaBag} m²
                                                                        </td>
                                                                    </tr>
                                                                }
                                                                <tr className={classNames.row}>
                                                                    <th className={classNames.heading}>
                                                                        Oppervlakte-cat.
                                                                    </th>
                                                                    <td className={classNames.data}>
                                                                        {!request.partnership && data.filter.options.buildingType
                                                                            .filter(
                                                                                type => type.category === request.buildingTypeCategory &&
                                                                                    type.value === request.buildingType
                                                                            )
                                                                            .map((areaOptions) => (
                                                                                areaOptions.areas.filter(option => option.value == request.area).map((area) => (
                                                                                    <span key={area.value}>
                                                                                                {area.label} m²
                                                                                            </span>
                                                                                ))
                                                                            ))
                                                                        }

                                                                        {request.partnership && request.area &&
                                                                            <span>
                                                                                {request.area} m²
                                                                            </span>
                                                                        }
                                                                    </td>
                                                                </tr>
                                                                <tr className={classNames.row}>
                                                                    <th className={classNames.heading}>
                                                                        Invoer
                                                                    </th>
                                                                    <td className={classNames.data}>
                                                                        {request.areaManual ? 'Handmatig' : 'BAG'}
                                                                    </td>
                                                                </tr>
                                                                </tbody>
                                                            </table>

                                                            {request.billingStreet &&
                                                                <div className={classNames.billingAddress}>
                                                                    <label
                                                                        className={classNames.heading}>Factuuradres</label>
                                                                    <div>
                                                                        <TruncateText
                                                                            textInput={billingAddress}
                                                                            textOutput={billingAddress}
                                                                            count={25}
                                                                        />
                                                                        <br/>
                                                                        <TruncateText
                                                                            textInput={request.billingZipcode + request.billingCity}
                                                                            textOutput={`${request.billingZipcode} ${request.billingCity}`}
                                                                            count={25}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                        <div className={classNames.blockThree}>
                                                            <table className={classNames.table}>
                                                                <tbody className={classNames.body}>
                                                                <tr className={classNames.row}>
                                                                    <th className={classNames.heading}>
                                                                        Methodiek
                                                                    </th>
                                                                    <td className={classNames.data}>
                                                                        {request.methodologyName}
                                                                    </td>
                                                                </tr>
                                                                <tr className={classNames.row}>
                                                                    <th className={classNames.heading}>Prijs</th>
                                                                    <td className={classNames.data}>
                                                                        {request.price !== null ? '€ ' + request.price.replace('.', ',') : ''}
                                                                        {request.requiresQuote ? 'Prijsopgave' : ''}
                                                                    </td>
                                                                </tr>
                                                                <tr className={classNames.row}>
                                                                    <th className={classNames.heading}>
                                                                        Levertijd
                                                                    </th>
                                                                    <td className={classNames.data}>
                                                                        {request.deliveryTime} werkdag(en)
                                                                    </td>
                                                                </tr>
                                                                {request.partnership && request.workOrder &&
                                                                    <tr className={classNames.row}>
                                                                        <th className={classNames.heading}>
                                                                            Partnertraject
                                                                        </th>
                                                                        <td className={classNames.data}>
                                                                            {request.partnership.name}
                                                                        </td>
                                                                    </tr>
                                                                }
                                                                {request.partnership && request.workOrder &&
                                                                    <tr className={classNames.row}>
                                                                        <th className={classNames.heading}>
                                                                            Status
                                                                        </th>
                                                                        <td className={classNames.data}>
                                                                            {request.workOrder.status.name}
                                                                        </td>
                                                                    </tr>
                                                                }
                                                                </tbody>
                                                            </table>

                                                            {request.requestContact && request.requestContact.name &&
                                                                <div className={classNames.requestContact}>
                                                                    <label className={classNames.heading}>Contact
                                                                        voor opname</label>
                                                                    <div>
                                                                        <TruncateText
                                                                            textInput={request.requestContact.firstName + request.requestContact.affix + request.requestContact.name}
                                                                            textOutput={`${request.requestContact.firstName} ${request.requestContact.affix} ${request.requestContact.name}`}
                                                                            count={25}
                                                                        />
                                                                        <br/>
                                                                        <a href={`tel:${request.requestContact.phone}`}>
                                                                            {request.requestContact.phone}
                                                                        </a>
                                                                        <br/>
                                                                        <a
                                                                            href={`mailto:${request.requestContact.email}`}
                                                                        >
                                                                            <TruncateText
                                                                                textInput={request.requestContact.email}
                                                                                textOutput={request.requestContact.email}
                                                                                count={25}
                                                                            />
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    {(request.extraInfoVisit || request.extraInfoMessage || request.orderFilename) &&
                                                        <div className={classNames.extra}>
                                                            {request.extraInfoVisit &&
                                                                <>
                                                                    <strong className={classNames.label}>
                                                                        Wanneer schikt het jou dat de
                                                                        energielabel-adviseur langskomt
                                                                        voor een gebouwopname?
                                                                    </strong>
                                                                    <span>{request.extraInfoVisit}</span>
                                                                </>
                                                            }

                                                            {request.extraInfoMessage &&
                                                                <>
                                                                    <strong className={classNames.label}>
                                                                        Wil je nog iets kwijt?
                                                                    </strong>
                                                                    <span
                                                                        dangerouslySetInnerHTML={{__html: request.extraInfoMessage}}></span>
                                                                </>
                                                            }

                                                            {request.orderFilename &&
                                                                <>
                                                                    <strong className={classNames.label}>
                                                                        Opdrachtbevestiging
                                                                    </strong>
                                                                    <a href={`${process.env.GATSBY_API_ADVISOR_URL}/request/${request.id}/confirmation-pdf`}
                                                                       target="_blank" rel="noreferrer">
                                                                        Download opdrachtbevestiging
                                                                    </a>
                                                                </>
                                                            }
                                                        </div>
                                                    }

                                                    {request.partnership && request.workOrder &&
                                                        <form
                                                            className={classNames.appointments}
                                                            method="POST"
                                                            onSubmit={onUpdateMeasurementDatesButtonClick(request)}
                                                        >
                                                            <div className={classNames.columns}>
                                                                {(request.workOrder.status.code === 'planned_1' || request.workOrder.status.code === 'requested') &&
                                                                    <div className={classNames.column}>
                                                                        <UpdateMeasurementDate
                                                                            labelText="Opnamedatum 0-meting"
                                                                            defaultValue={firstMeasurementDate}
                                                                            onChange={(date) => setFirstMeasurementAppointmentDate(date)}
                                                                            minDate={request.capacityStartDate}
                                                                            maxDate={request.capacityEndDate}
                                                                        />
                                                                        {request.workOrder.status.code === ('planned_1') &&
                                                                            <>
                                                                                <label
                                                                                    className={classNames.headerLabel}>
                                                                                    Toelichtend gesprek heeft
                                                                                    plaatsgevonden?
                                                                                </label>
                                                                                <div className={classNames.radio}>
                                                                                    <InputRadio
                                                                                        labelClassName={classNames.inputLabel}
                                                                                        name="adviceMeeting"
                                                                                        defaultChecked={adviceMeeting !== null && adviceMeeting}
                                                                                        onChange={(event) => {
                                                                                                setAdvisoralLectureGiven(true);
                                                                                            }
                                                                                        }
                                                                                        disabled={adviceMeeting !== null}
                                                                                        required={ !!((firstMeasurementDate || firstMeasurementAppointmentDate) && (request.energyLabelRatingFirstMeasurement !== "No label" && !firstMeasurementLabel || firstMeasurementLabel !== "No label" && firstMeasurementLabel !== ""))
                                                                                        }
                                                                                    >
                                                                                        Ja
                                                                                    </InputRadio>
                                                                                    <InputRadio
                                                                                        labelClassName={classNames.inputLabel}
                                                                                        name="adviceMeeting"
                                                                                        defaultChecked={adviceMeeting !== null && !adviceMeeting}
                                                                                        onChange={(event) => {
                                                                                                setAdvisoralLectureGiven(false);
                                                                                            }
                                                                                        }
                                                                                        disabled={adviceMeeting !== null}
                                                                                        required={ !!((firstMeasurementDate || firstMeasurementAppointmentDate) && (request.energyLabelRatingFirstMeasurement !== "No label" && !firstMeasurementLabel || firstMeasurementLabel !== "No label" && firstMeasurementLabel !== ""))
                                                                                        }
                                                                                    >
                                                                                        Nee
                                                                                    </InputRadio>
                                                                                </div>
                                                                            </>
                                                                        }

                                                                        {request.workOrder.status.code === ('planned_1') && (advisoralLectureGiven || adviceMeeting) &&
                                                                            <>
                                                                                <label
                                                                                    className={classNames.headerLabel}>
                                                                                    Hoe heeft het toelichtende gesprek
                                                                                    plaatsgevonden?
                                                                                </label>
                                                                                <div className={classNames.radio}>
                                                                                    <InputRadio
                                                                                        labelClassName={classNames.inputLabel}
                                                                                        name="adviceMeetingPhysical"
                                                                                        defaultChecked={adviceMeetingPhysical !== null && adviceMeetingPhysical ? true : false}
                                                                                        onChange={(event) => {
                                                                                                setAdvisoralLectureGivenPhysical(true);
                                                                                            }
                                                                                        }
                                                                                        disabled={adviceMeetingPhysical !== null}
                                                                                        required={true}
                                                                                    >
                                                                                        Fysiek
                                                                                    </InputRadio>
                                                                                    <InputRadio
                                                                                        labelClassName={classNames.inputLabel}
                                                                                        name="adviceMeetingPhysical"
                                                                                        defaultChecked={adviceMeetingPhysical !== null && !adviceMeetingPhysical ? true : false}
                                                                                        onChange={(event) => {
                                                                                                setAdvisoralLectureGivenPhysical(false);
                                                                                            }
                                                                                        }
                                                                                        disabled={adviceMeetingPhysical !== null}
                                                                                        required={true}
                                                                                    >
                                                                                        Niet fysiek
                                                                                    </InputRadio>
                                                                                </div>
                                                                            </>
                                                                        }
                                                                    </div>
                                                                }
                                                                {request.workOrder.status.code === 'planned_1' &&
                                                                    <div className={classNames.column}>
                                                                        <UpdateMeasurementLabel
                                                                            labelText="Energielabel 0-meting"
                                                                            labelType="energyLabelRatingFirstMeasurement"
                                                                            defaultValue={request.energyLabelRatingFirstMeasurement}
                                                                            onChange={(event) => {
                                                                                setFirstMeasurementLabel(event.currentTarget.value);
                                                                            }
                                                                            }
                                                                        />
                                                                    </div>
                                                                }
                                                            </div>
                                                            <div className={classNames.columns}>
                                                                {(request.workOrder.status.code === 'planned_2' || request.workOrder.status.code === 'planning_2') &&
                                                                    <div className={classNames.column}>
                                                                        <UpdateMeasurementDate
                                                                            labelText="Opnamedatum eind-meting"
                                                                            defaultValue={finalMeasurementDate}
                                                                            onChange={(date) => setFinalMeasurementAppointmentDate(date)}
                                                                            minDate={firstMeasurementDate ? firstMeasurementDate : new Date()}
                                                                        />
                                                                    </div>
                                                                }
                                                                {request.workOrder.status.code === 'planned_2' &&
                                                                    <div className={classNames.column}>
                                                                        <UpdateMeasurementLabel
                                                                            labelText="Energielabel eind-meting"
                                                                            labelType="energyLabelRatingFinalMeasurement"
                                                                            defaultValue={request.energyLabelRatingFinalMeasurement}
                                                                            onChange={(event) => {
                                                                                setFinalMeasurementLabel(event.currentTarget.value);
                                                                            }
                                                                            }
                                                                        />
                                                                    </div>
                                                                }
                                                            </div>

                                                            {(request.workOrder.status.code === 'requested' ||
                                                                    request.workOrder.status.code === 'planned_1' ||
                                                                    request.workOrder.status.code === 'planned_2' ||
                                                                    request.workOrder.status.code === 'planning_2') &&
                                                                <div className={classNames.columns}>
                                                                    <div className={classNames.column}>
                                                                        <Button
                                                                            className={classNames.updateButton}>
                                                                            Bijwerken
                                                                        </Button>
                                                                    </div>
                                                                    <div className={classNames.column}>
                                                                        {labelErrors &&
                                                                            <span
                                                                                className={classNames.error}>{labelErrors}</span>
                                                                        }

                                                                        {success &&
                                                                            <span
                                                                                className={classNames.success}>{success}</span>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            }
                                                        </form>
                                                    }

                                                    {Object.entries(request.files).length > 0 && request.partnership &&
                                                        <div className={classNames.labelFiles}>
                                                            <strong className={classNames.label}>Bestanden</strong>
                                                            {Object.entries(request.files).map((file, index) =>
                                                                <LabelFileUpdater
                                                                    key={index}
                                                                    requestId={request.id}
                                                                    file={file}
                                                                    status={request.workOrder ? request.workOrder.status.code : ""}
                                                                    isPartnership={request.partnership}
                                                                />
                                                            )}
                                                        </div>
                                                    }

                                                    <div className={classNames.actions}>
                                                        {(!(actualTab == "conflicted" || actualTab == "completed" || actualTab == "canceled" || actualTab == "expired")) && !request.partnership && (
                                                            <div className={classNames.contacted}>
                                                                Contact met de klant opgenomen?
                                                                <Switch
                                                                    checked={!(!(request.activatedAt))}
                                                                    className={classNames._switch}
                                                                    onChange={onSwitchChange(request)}
                                                                />
                                                            </div>
                                                        )}

                                                        {(actualTab == "processing") &&
                                                            <div className={classNames.completed}>
                                                                <Button
                                                                    className={classNames.actionButton}
                                                                    onClick={onPlannedButtonClick(request)}>
                                                                    Ingepland
                                                                </Button>
                                                            </div>
                                                        }
                                                        {(!request.partnership && !(actualTab == "conflicted" || actualTab == "new" || actualTab == "canceled" || actualTab == "expired" || actualTab == "completed" || actualTab == "processing")) &&
                                                            <div className={classNames.completed}>
                                                                <Button
                                                                    className={classNames.actionButton}
                                                                    onClick={onDeliverButtonClick(request)}>
                                                                    Geleverd en afronden
                                                                </Button>
                                                            </div>
                                                        }
                                                    </div>
                                                    {(actualTab == "new" || actualTab == "processing" || actualTab == "planned") && !request.partnership && (
                                                        <form
                                                            className={classNames.conflict}
                                                            method="POST"
                                                            onSubmit={onConflictFormSubmit(request)}
                                                        >
                                                            <strong className={classNames.label}>Deze aanvraag
                                                                kan niet doorgaan, omdat:</strong>
                                                            {/*<Select*/}
                                                            {/*    className={ classNames.select }*/}
                                                            {/*    onChange={ (event) => setConflictReason(event.currentTarget.value) }*/}
                                                            {/*    required*/}
                                                            {/*    value={ conflictReason }*/}
                                                            {/*>*/}
                                                            {/*    <option disabled value="">-- Kies een reden --</option>*/}
                                                            {/*    { data.allRequestRejectReason.nodes.map((reason) => (*/}
                                                            {/*        <option key={ reason.code } value={ reason.code }>*/}
                                                            {/*            { reason.name }*/}
                                                            {/*        </option>*/}
                                                            {/*    )) }*/}
                                                            {/*</Select>*/}
                                                            <Textarea
                                                                className={classNames.textarea}
                                                                onChange={(event) => setConflictExplanation(event.currentTarget.value)}
                                                                placeholder="Omschrijf de reden..."
                                                                required
                                                                value={conflictExplanation}
                                                            />
                                                            <Button
                                                                className={classNames.conflictButton}
                                                            >
                                                                Indienen
                                                            </Button>
                                                        </form>
                                                    )}
                                                    {(actualTab == "conflicted" || actualTab == "expired" || actualTab == "canceled") && (
                                                        <>
                                                            {request.conflict && (
                                                                <div className={classNames.conflict}>
                                                                    <strong className={classNames.label}>Deze
                                                                        aanvraag kan niet doorgaan,
                                                                        omdat:</strong>
                                                                    {/*<span>{ request.conflict.reason.name }</span>*/}
                                                                    <div className={classNames.notes}>
                                                                        <div className={classNames.advisorNote}>
                                                                            <div
                                                                                className={classNames.label}>JIJ
                                                                            </div>
                                                                            <div className={classNames.content}>
                                                                                <time
                                                                                    className={classNames.date}
                                                                                    dateTime={request.conflict.advisorNoteAt}
                                                                                >
                                                                                    {moment(request.conflict.advisorNoteAt).format(
                                                                                        "DD-MM-YYYY HH:mm"
                                                                                    )}
                                                                                </time>
                                                                                <br/>
                                                                                {request.conflict.advisorNote}
                                                                            </div>
                                                                        </div>
                                                                        {request.conflict.buildingOwnerNote && (
                                                                            <div
                                                                                className={classNames.buildingOwnerNote}>
                                                                                <div
                                                                                    className={classNames.label}>KLANT
                                                                                </div>
                                                                                <div
                                                                                    className={classNames.content}>
                                                                                    <time
                                                                                        className={classNames.date}
                                                                                        dateTime={request.conflict.buildingOwnerNoteAt}
                                                                                    >
                                                                                        {moment(request.conflict.buildingOwnerNoteAt).format(
                                                                                            "DD-MM-YYYY HH:mm"
                                                                                        )}
                                                                                    </time>
                                                                                    <br/>
                                                                                    {request.conflict.buildingOwnerNote}
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </>
                                                    )}
                                                </td>
                                            </tr>
                                        }
                                    </Fragment>
                                );
                            })}
                        </>
                    }
                    </tbody>
                </table>
                {!(isFetchingRequests) && pages > 0 &&
                    <div className={classNames.paginate}>
                        <ReactPaginate
                            activeLinkClassName={classNames.active}
                            breakLinkClassName={classNames._break}
                            containerClassName={classNames.list}
                            disabledClassName={classNames.disabled}
                            forcePage={page - 1}
                            marginPagesDisplayed={1}
                            nextLinkClassName={classNames.next}
                            nextLabel="Volgende"
                            onPageChange={({selected}) => {
                                setPage(selected + 1);
                            }}
                            pageClassName={classNames.listItem}
                            pageCount={pages}
                            pageLinkClassName={classNames.anchor}
                            pageRangeDisplayed={2}
                            previousLinkClassName={classNames.previous}
                            previousLabel="Vorige"
                        />
                    </div>
                }
            </div>
        </div>
    );
};

export default MyRequestsView;
