import React, {FormEvent, useRef, useState} from 'react';

import * as classNames from "@css/component/LabelFileUpdater.module.scss";
import Button from "@js/component/Button";
import {useSelector} from "react-redux";
import StoreState from "@js/store/StoreState";
import Input from "@js/component/Input";

/**
 * @type LabelFileUpdaterProps
 */
type LabelFileUpdaterProps = {
    requestId: number;
    file: {};
    isPartnership?: boolean;
    status: string;
};

const LabelFileUpdater = (props: LabelFileUpdaterProps) => {
    const {
        requestId,
        file,
        isPartnership,
        status,
        ...restProps
    } = props;

    // const defaultFileNames = [
    //     { type: "vabiFile", name: "VABI Bestand" },
    //     { type: "energyLabelFile", name: "Energielabel" },
    // ];

    const partnershipFileNames = [
        { type: "vabiFilePhaseOne", name: "VABI Energielabel 0-Meting & Maatwerkadvies (EPA-bestand)", allowedTypes: "application/octet-stream", disabled: status && status === 'planned_1' ? false : true, accept: ".epa" },
        { type: "vabiExportFilePhaseOne", name: "Monitorbestand Energielabel 0-Meting (XML-bestand)", allowedTypes: "text/xml", disabled: status && status === 'planned_1' ? false : true },
        { type: "energyLabelFirstMeasurementFile", name: "Energielabel 0-Meting (PDF-bestand)", allowedTypes: "application/pdf", disabled: status && status === 'planned_1' ? false : true },
        { type: "customizedAdviceFile", name: "Maatwerkadvies (PDF-bestand)", allowedTypes: "application/pdf", disabled: status && status === 'planned_1' ? false : true },
        { type: "vabiFilePhaseTwo", name: "VABI Energielabel Actualisering (EPA-bestand)", allowedTypes: "application/octet-stream", disabled: status && status === 'planned_2' ? false : true, accept: ".epa" },
        { type: "vabiExportFilePhaseTwo", name: "Monitorbestand Energielabel Actualisering (XML-bestand)", allowedTypes: "text/xml", disabled: status && status === 'planned_2' ? false : true },
        { type: "energyLabelFinalMeasurementFile", name: "Energielabel Actualisering (PDF-bestand)", allowedTypes: "application/pdf", disabled: status && status === 'planned_2' ? false : true },
    ];

    const token = useSelector((storeState: StoreState) => storeState.security.token);
    const inputFile = useRef(null);
    const [chosenFile, setChosenFile] = useState<File | null>(null);
    const [errors, setErrors] = useState<Array<string>>([]);
    const [success, setSuccess] = useState<Array<string>>([]);
    //const fileNames = isPartnership ? partnershipFileNames : defaultFileNames;
    const replacementFileName = partnershipFileNames.find(item => item.type === file[0]).name;
    const allowedFileTypes = partnershipFileNames.find(item => item.type === file[0]).allowedTypes;
    const accept = partnershipFileNames.find(item => item.type === file[0]).accept;
    const isDisabled = partnershipFileNames.find(item => item.type === file[0]).disabled;
    const [fileLocation, setFileLocation] = useState<string>(file[1].url);
    const [fileName, setFileName] = useState<string>(file[1].name);

    const handleDownload = () => {
        fetch(fileLocation)
            .then((response) => response.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');

                link.href = url;
                link.setAttribute(
                    'download',
                    fileName,
                );

                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            });
    }

    const handleUpload = async (e: FormEvent) => {
        e.preventDefault();

        if (!chosenFile) {
            return;
        }

        const formData = new FormData();
        formData.append(file[0], chosenFile);

        fetch(`${process.env.GATSBY_API_ADVISOR_URL}/request/${requestId}/upload-files?token=${token}`, {
            body: formData,
            method: "POST"
        })
            .then((response) => response.json())
            .then((data) => {
               if("succeeded" === data.status) {
                   const newFile = Object.entries(data.files).find(([key, value]) => key === file[0])[1];

                   setFileLocation(newFile.url);
                   setFileName(newFile.name);

                   inputFile.current.value = '';

                   setErrors(null);
                   setSuccess('Bestand is geüpload');
               } else {
                   if(data.errors) {
                       Object.entries(data.errors).map((reason: string) => {
                           setErrors(reason[1]);
                           setSuccess(null);
                       });
                   }
               }
            }).catch((error) => {
                console.log(error);
            });
    };

    return (
        <div { ...restProps } className={ classNames.fileUploader }>
            <label className={ classNames.label }>
                { replacementFileName }
            </label>

            { fileLocation && fileName &&
                <span onClick={ handleDownload } className={ classNames.link }>Download bestand</span>
            }

            <div className={ classNames.fileInfo}>
                <Input
                    className={classNames.input}
                    onChange={ (event) => {
                        if (event.target.files) {
                            setChosenFile(event.target.files[0]);
                        }
                    }}
                    type="file"
                    accept={ accept ?? allowedFileTypes }
                    id={`file_${file[0].toLowerCase()}`}
                    inputRef={ inputFile }
                    disabled={ isDisabled }
                />
                <Button
                    className={ classNames.uploadButton }
                    onClick={ handleUpload }
                    disabled={ isDisabled }
                >
                    Uploaden
                </Button>
            </div>

            { errors && <span className={ classNames.error }>{ errors }</span> }

            { success && <span className={ classNames.success }>{ success }</span> }
        </div>
    );
}

export default LabelFileUpdater;
