// extracted by mini-css-extract-plugin
export var blockOne = "MyRequests-module--blockOne--ztX98";
export var blockTwo = "MyRequests-module--blockTwo--fMj-d";
export var blocks = "MyRequests-module--blocks--ddfzs";
export var box = "MyRequests-module--box--ZxW+q";
export var breadcrumbs = "MyRequests-module--breadcrumbs--nT7Fm";
export var container = "MyRequests-module--container--Nz8cX";
export var current = "MyRequests-module--current--t3OOI";
export var header = "MyRequests-module--header--FO+AE";
export var heading = "MyRequests-module--heading--zLmr3";
export var icon = "MyRequests-module--icon--yF6c2";
export var main = "MyRequests-module--main--7PCs0";
export var myRequestsPage = "MyRequests-module--myRequestsPage--3Ow56";
export var previous = "MyRequests-module--previous--+loXa";