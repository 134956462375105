import React from "react";

import { DetailedHTMLProps } from "react";
import { SelectHTMLAttributes } from "react";

import clsx from "clsx";

import * as classNames from "@css/component/Select.module.scss";

/**
 * @type SelectProps
 */
export type SelectProps = DetailedHTMLProps<SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>;

/**
 * @const Select
 */
const Select = (props: SelectProps) => {
    const {
        className,
        ...restProps
    } = props;

    return (
        <select { ...restProps } className={ clsx(classNames.select, className) } />
    );
};

export default Select;
