import React from 'react';

/**
 * @type TruncateTextProps
 */
type TruncateTextProps = {
    textInput: string;
    textOutput: string;
    count: number;
}

/**
 * @const TruncateText
 */
const TruncateText = (props: TruncateTextProps) => {
    const {
        textInput,
        textOutput,
        count,
        ...restProps
    } = props;

    return (
        <span { ...restProps } title={ textInput.length >= count ? textOutput : '' }>
            { textInput.length >= count ? textOutput.slice(0, count) + '...' : textOutput }
        </span>
    );
}

export default TruncateText;